import MedicalSpeciality from '@/statics/medicalSpeciality';
import Range from '@/statics/range';
import Trolley from '@/statics/trolley';
import { Feature, GIProcedureArea, LowerGILength, RespiratoryType } from '@/statics/scopeFilter';
import { LocaleCode } from '@/statics/locale';
import { ProcedureType, ProcedureTypeSelectOptions } from '@/statics/procedureType';

export default {
  meta: {
    /* same as meta infos in index_it.html */
    title: 'Sistema a torre per endoscopia - Configurazione in 5 passaggi',
    description:
      "Selezionare l'ambito delle proprie procedure e ottimizzarle in base alle esigenze per ricevere una configurazione personalizzata della torre per endoscopia tramite e-mail."
  },
  locale: {
    [LocaleCode.DE]: 'Tedesco',
    [LocaleCode.EN]: 'Inglese (US)',
    [LocaleCode.EN_GB]: 'Inglese (UK)',
    [LocaleCode.ES]: 'Spagnolo',
    [LocaleCode.IT]: 'Italiano',
    [LocaleCode.NL]: 'Olandese',
    [LocaleCode.RU]: 'Russo'
  },
  step: 'Fase',
  quantity: 'Quantità',
  shortPcsCounted: '{n} pz.',
  progressBarTitles: {
    gettingStarted: '@:gettingStarted.title',
    procedures: '@:procedures.title',
    optimize: '@:optimize.title',
    accessories: '@:accessories.title',
    request: '@:request.title'
  },
  loadingConfiguration: 'Configurazione di caricamento...',
  gettingStarted: {
    title: 'Introduzione',
    startScreenHeadline: 'Configurare la torre endoscopica in pochi passaggi.',
    medicalSpecialityHeadline: 'Scegliere la propria disciplina medica',
    medicalSpeciality: {
      [MedicalSpeciality.GASTROENTEROLOGY]: 'Gastroenterologia',
      [MedicalSpeciality.PULMONOLOGY]: 'Pneumologia'
    },
    trolleyProcedureTypesHeadline: "Selezionare l'obiettivo delle proprie procedure",
    procedureTypeSelectOptions: {
      [ProcedureTypeSelectOptions.ADVANCED]: 'Procedure e opzioni avanzate',
      [ProcedureTypeSelectOptions.GENERAL]: 'Procedure generiche'
    }
  },
  procedures: {
    title: 'Procedure',
    headline: "Scegliere le proprie procedure e l'obiettivo della configurazione desiderata.",
    procedureRequest: 'Per quali procedure si intende investire in nuove apparecchiature?',
    procedureTypes: {
      [ProcedureType.DIAGNOSTIC]: 'Procedure generiche',
      [ProcedureType.THERAPEUTIC]: 'Procedure e opzioni avanzate'
    },
    errorNoProcedureSelected: 'Selezionare almeno una procedura.',
    selectAll: 'Seleziona tutto',
    resetSelection: 'Ripristina selezione',
    hasRequirementsInfo: 'Per prima cosa, selezionare una delle procedure seguenti.',
    isRequirementInfo: 'Per prima cosa, selezionare almeno una procedura:',
    rangeRequest: "Selezionare l'obiettivo della configurazione desiderata",
    range: {
      [Range.BASIC]: 'Miglior prezzo',
      [Range.MID_RANGE]: 'Prezzo e innovazione',
      [Range.INNOVATION]: 'Migliori prestazioni'
    }
  },
  optimize: {
    title: 'Ottimizzazione',
    trolleys: {
      [Trolley.MAIN]: 'Torre endoscopica',
      [Trolley.GHOST]: 'Articoli aggiuntivi',
      [Trolley.ESG]: 'Generatori ad alta frequenza'
    },
    trolleyTitles: {
      [Trolley.MAIN]: 'Torre endoscopica',
      [Trolley.GHOST]: `@:optimize.trolleys.${Trolley.GHOST}`,
      [Trolley.ESG]: `@:optimize.trolleys.${Trolley.ESG}`
    },
    empty: 'Vuoto',
    addComponent: 'Aggiungi compenente',
    scopeGuide: 'ScopeGuide',
    zones: {
      M0: 'Monitor',
      M1: 'Intelligenza artificiale',
      M2: 'Processori video',
      M3: 'Processori a ultrasuoni',
      M4: 'Pompe',
      M5: 'Insufflatore di CO²',
      M6: 'Generatori ad alta frequenza',
      M7: 'Endoscopi'
    },
    selectedCounted: '{count} Selezionato',
    viewESG: 'Generatori ad alta frequenza',
    ghostDescription:
      ' Se non tutti gli elementi selezionati si adattano fisicamente alla torre endoscopica, in questa sezione vengono visualizzati elementi aggiuntivi (solo a scopo di visualizzazione).',
    esgDescription: 'ESG-300 è dotato del carrello TC-E300 (e di unità argon-plasma opzionale).',
    supportHintMedicalSpeciality: 'solo {supported}'
  },
  changeArticle: {
    subline: 'Configurazione personalizzata',
    headline: 'Modifica prodotti',
    showDetails: 'Mostra dettagli',
    scopesSelectedCounted: '{count} x endoscopi selezionati |{count} x selezionati',
    currentlySelected: 'Attualmente selezionati',
    productCode: 'Codice prodotto',
    hasRequirementsInfo: 'Per prima cosa, selezionare uno di questi prodotti:',
    isRequirementInfo: 'Per prima cosa, eliminare questi prodotti selezionati:',
    technicalSpecifications: 'Specifiche tecniche',
    noArticlesAvailable:
      'Nessun articolo compatibile con il processore video attualmente selezionato.',
    noScopesAvailable:
      'Nessun endoscopio compatible di questa selezionedi con il processore video attualmente selezionato.',
    scopeGuideSubline: "Sistema di navigazione dell'immagine 3D",
    scopeGuideIntro: "Come funziona il nostro ScopeGuide sistema di navigazione dell'immagine 3D",
    scopeGuideSummary:
      "ScopeGuide offre un'immagine 3D in tempo reale della posizione e della configurazione dell'endoscopio all'interno del colon.",
    scopeGuideDescription:
      "Le bobine elettromagnetiche integrate lungo la sonda di inserimento dedicata dell'endoscopio generano un campo magnetico pulsato di bassa intensità che viene raccolto dall'antenna ricevitrice. <br /> Gli impulsi magnetici consentono di calcolare con precisione la posizione e l'orientamento della sonda di inserimento, producendo un'immagine tridimensionale direttamente sul monitor.",
    scopeGuideIncludedItems: 'Include i seguenti elementi:',
    notVisualized: '*Non visualizzato nella configurazione carrello.'
  },
  scopeFilter: {
    area: {
      label: 'Area',
      items: {
        [GIProcedureArea.UPPER_GI]: 'Tratto GI superiore',
        [GIProcedureArea.LOWER_GI]: 'Tratto GI inferiore',
        [GIProcedureArea.ERCP]: 'ERCP',
        [GIProcedureArea.ENTEROSCOPY]: 'Enteroscopia',
        [GIProcedureArea.ULTRASOUND]: 'Ultrasuoni'
      }
    },
    length: {
      label: 'Lunghezza endoscopio',
      items: {
        [LowerGILength.INTERMEDIATE]: 'Intermedio',
        [LowerGILength.LONG]: 'Lungo'
      }
    },
    respiratoryType: {
      label: 'Tipo endoscopio',
      items: {
        [RespiratoryType.BRONCHOSCOPE]: 'Broncoscopio',
        [RespiratoryType.MINIPROBE]: 'Minisonda',
        [RespiratoryType.PLEURASCOPE]: 'Pleuroscopio',
        [RespiratoryType.ULTRASOUND]: 'Ultrasuoni'
      }
    },
    features: {
      label: 'Funzionalità',
      items: {
        [Feature.WATER_JET]: "Getto d'acqua",
        [Feature.HQ]: 'Compatibile con strumenti ad alta frequenza',
        [Feature.TRANSNASAL]: 'Transnasale',
        [Feature.PCF]: 'PCF'
      }
    },
    series: {
      label: 'Serie endoscopio'
    }
  },
  accessories: {
    title: 'Accessori',
    headline:
      'Per un sistema endoscopico completamente equipaggiato, raccomandiamo i seguenti accessori'
  },
  request: {
    title: 'Invio della richiesta',
    subline: 'La sua selezione',
    headline: "Inviare la richiesta per ricevere un'offerta individuale",
    areaTrolley: 'Torre endoscopica',
    areaAccessories: 'Accessori'
  },
  success: {
    title: 'La richiesta è stata inviata',
    headline: 'Grazie per averci contattato.',
    subline:
      'La sua richiesta di configurazione della torre endoscopica è stata inviata con successo. <br /> Le risponderemo il prima possibile.',
    secondSubline: 'Scopri di più su EVIS X1',
    buttonNew: 'Avviare una nuova configurazione',
    buttonDetail: 'Ulteriori informazioni',
    detailAssetPath: '/assets/successScreen_bottom_en.png',
    detailLink:
      'https://www.olympus.it/medical/it/Prodotti-e-soluzioni/Prodotti/Prodotti/EVIS-X1.html'
  },
  contact: {
    subline: 'Contatti',
    headline: 'Richiedere assistenza',
    info: 'Serve aiuto per configurare la torre endoscopica? Ci contatti: le risponderemo il prima possibile.',
    success: 'La richiesta è stata inviata.<br />Le risponderemo il prima possibile.',
    customerQuestionHeadline: 'Richiesta',
    backToConfiguration: 'Torna alla configurazione'
  },
  saveConfiguration: {
    title: 'Continua più tardi',
    subtitle: 'Salva per dopo',
    subline:
      'Inserisci il tuo indirizzo e-mail e ti invieremo un link per riprendere la configurazione.',
    sendLink: 'Inviami il link',
    copyLinkToClipboard: 'Copia questo link negli Appunti',
    emailHeadline: "Qual'è la tua e-mail?",
    writeClipboardSuccess: 'Copiato negli Appunti',
    writeClipboardFailed: 'Copia negli Appunti non riuscita',
    successHeadline: 'Configurazione salvata',
    successSubline:
      "La tua configurazione è stata salvata con successo.<br /> Continua in qualsiasi momento seguendo il link qui sotto o dall'email che ti invieremo a breve."
  },
  footerUrlsLabel: {
    imprint: 'Impronta',
    termsOfUse: "Condizioni d'uso",
    privacyNotice: 'Informativa sulla privacy',
    cookies: 'Cookie',
    cookiesSettings: 'Impostazioni cookie',
    socialMedia: 'Social Media'
  },
  contactForm: {
    country: 'Paese',
    countryHeadline: 'Selezionare il proprio Paese',
    customerName: 'Nome e cognome',
    customerNameHeadline: 'Come si chiama?',
    email: 'Indirizzo e-mail',
    emailHeadline: 'Qual è il suo indirizzo e-mail?',
    customerPhoneNumber: 'Numero di telefono',
    customerPhoneNumberHeadline: 'Qual è il suo numero di telefono?',
    customerCompany: 'Ospedale/organizzazione',
    customerCompanyHeadline: 'Per quale ospedale/organizzazione lavora?',
    customerQuestion: 'Invia richiesta',
    customerQuestionHeadline: "C'è qualcos'altro che vorrebbe dirci?",
    privacy:
      'Olympus rispetta la vostra privacy.  Si prega di consultare il nostro {link} per informazioni su come trattiamo i vostri dati personali per rispondere alla vostra richiesta.',
    checkboxRequired: '*Indica un campo obbligatorio'
  },
  actions: {
    back: 'Indietro',
    confirm: 'Conferma',
    continue: 'Avanti',
    no: 'No',
    save: 'Salva',
    select: 'Seleziona',
    selectNothing: 'Non selezionare niente',
    selected: 'Selezionato',
    selectedNothing: 'Niente selezionato',
    sendRequest: 'Invia richiesta',
    submit: 'Invia',
    yes: 'Sì'
  },
  error: {
    alert: 'Qualcosa è andato storto. Si prega di riprovare più tardi.',
    title: 'Qualcosa è andato storto',
    subline: "Molte grazie per l'interesse dimostrato verso i dispositivi Olympus!",
    info: "Sfortunatamente si è verificato un errore. Attendere qualche secondo e ripetere l'ultima azione o aggiornare la pagina.",
    contact: 'Contattare il nostro {0}.',
    customerService: 'servizio clienti',
    refresh: 'Aggiorna la pagina',
    retry: 'Riprova'
  },
  formData: {
    customerName: 'Nome',
    customerCompany: '@:contactForm.customerCompany',
    country: 'Paese',
    email: 'E-mail',
    customerPhoneNumber: 'Numero di telefono',
    customerQuestion: 'Messaggio',
    optional: 'facoltativo'
  },
  countryOptions: [
    { label: 'Albania', value: 'AL' },
    { label: 'Algeria', value: 'DZ' },
    { label: 'Angola', value: 'AO' },
    { label: 'Armenia', value: 'AM' },
    { label: 'Austria', value: 'AT' },
    { label: 'Azerbaigian', value: 'AZ' },
    { label: 'Bahrein', value: 'BH' },
    { label: 'Bielorussia', value: 'BY' },
    { label: 'Belgio', value: 'BE' },
    { label: 'Bosnia ed Erzegovina', value: 'BA' },
    { label: 'Botswana', value: 'BW' },
    { label: 'Bulgaria', value: 'BG' },
    { label: 'Camerun', value: 'CM' },
    { label: 'Croazia', value: 'HR' },
    { label: 'Cipro', value: 'CY' },
    { label: 'Repubblica Ceca', value: 'CZ' },
    { label: 'Danimarca', value: 'DK' },
    { label: 'Gibuti', value: 'DJ' },
    { label: 'Egitto', value: 'EG' },
    { label: 'Estonia', value: 'EE' },
    { label: 'Etiopia', value: 'ET' },
    { label: 'Finlandia', value: 'FI' },
    { label: 'Francia', value: 'FR' },
    { label: 'Georgia', value: 'GE' },
    { label: 'Germania', value: 'DE' },
    { label: 'Ghana', value: 'GH' },
    { label: 'Grecia', value: 'GR' },
    { label: 'Ungheria', value: 'HU' },
    { label: 'Islanda', value: 'IS' },
    { label: 'Iran', value: 'IR' },
    { label: 'Iraq', value: 'IQ' },
    { label: 'Israele', value: 'IL' },
    { label: 'Italia', value: 'IT' },
    { label: "Costa d'Avorio", value: 'CI' },
    { label: 'Giordania', value: 'JO' },
    { label: 'Kazakistan', value: 'KZ' },
    { label: 'Kenya', value: 'KE' },
    { label: 'Kuwait', value: 'KW' },
    { label: 'Kirghizistan (Kyrgyzstan)', value: 'KG' },
    { label: 'Lettonia', value: 'LV' },
    { label: 'Libano', value: 'LB' },
    { label: 'Lesotho', value: 'LS' },
    { label: 'Libia', value: 'LY' },
    { label: 'Lituania', value: 'LT' },
    { label: 'Macedonia', value: 'MK' },
    { label: 'Malawi', value: 'MW' },
    { label: 'Mauritania', value: 'MR' },
    { label: 'Mauritius', value: 'MU' },
    { label: 'Montenegro', value: 'ME' },
    { label: 'Marocco', value: 'MA' },
    { label: 'Mozambico', value: 'MZ' },
    { label: 'Namibia', value: 'NA' },
    { label: 'Paesi Bassi', value: 'NL' },
    { label: 'Nigeria', value: 'NG' },
    { label: 'Norvegia', value: 'NO' },
    { label: 'Oman', value: 'OM' },
    { label: 'Palestina', value: 'PS' },
    { label: 'Polonia', value: 'PL' },
    { label: 'Portogallo', value: 'PT' },
    { label: 'Qatar', value: 'QA' },
    { label: 'Repubblica democratica del Congo', value: 'CG' },
    { label: 'Romania', value: 'RO' },
    { label: 'Russia', value: 'RU' },
    { label: 'Ruanda', value: 'RW' },
    { label: 'Arabia Saudita', value: 'SA' },
    { label: 'Senegal', value: 'SN' },
    { label: 'Serbia e Montenegro', value: 'RS' },
    { label: 'Seychelles', value: 'SC' },
    { label: 'Slovacchia', value: 'SK' },
    { label: 'Slovenia', value: 'SI' },
    { label: 'Somalia', value: 'SO' },
    { label: 'Sudafrica', value: 'ZA' },
    { label: 'Sudan del Sud', value: 'SS' },
    { label: 'Spagna', value: 'ES' },
    { label: 'Sudan', value: 'SD' },
    { label: 'Swaziland', value: 'SZ' },
    { label: 'Svezia', value: 'SE' },
    { label: 'Svizzera', value: 'CH' },
    { label: 'Siria', value: 'SY' },
    { label: 'Tanzania', value: 'TZ' },
    { label: 'Togo', value: 'TG' },
    { label: 'Tunisia', value: 'TN' },
    { label: 'Turchia', value: 'TC' },
    { label: 'Regno Unito e Irlanda', value: 'GB' },
    { label: 'Uganda', value: 'UG' },
    { label: 'Ucrain', value: 'UA' },
    { label: 'Emirati Arabi Uniti', value: 'AE' },
    { label: 'Uzbekistan', value: 'UZ' },
    { label: 'Zambia', value: 'ZM' },
    { label: 'Zimbabwe', value: 'ZW' }
  ]
};
